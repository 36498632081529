import { FC, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import SelectDropdown from '~/src/components/generic/SelectDropdown'

import { Option } from '~/src/components/ReportIssue/types'

type Props = {
  options: Array<Option>
  selectedOption: Option
  isError: boolean
  isV2?: boolean
  validationError: string
  onOptionChange: (value: Option) => void
  onDetailsChanged: (value: string) => void
  onSubjectChanged: (value: string) => void
}

const CreateTaskStep: FC<Props> = ({
  options,
  selectedOption,
  isError,
  isV2,
  validationError,
  onOptionChange,
  onDetailsChanged,
  onSubjectChanged,
}) => {
  const subjectRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (subjectRef.current) {
      subjectRef.current.focus()
    }
  }, [])

  return (
    <div className="space-y-4">
      {!isV2 && (
        <div className="flex flex-col space-y-1">
          <div className="font-medium">Type of feedback</div>
          <SelectDropdown
            options={options}
            selectedOption={selectedOption}
            onOptionChange={onOptionChange}
            className="text-xm"
          />
        </div>
      )}

      <div className="flex flex-col space-y-1">
        <label htmlFor="subject" className="font-medium">
          Subject
        </label>
        <input
          id="subject"
          className={twMerge(
            'h-10 appearance-none rounded-md border border-gray-100 p-2 text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm',
            validationError ? 'border-red-600' : ''
          )}
          ref={subjectRef}
          type="text"
          placeholder="Enter a subject"
          onChange={(e) => onSubjectChanged(e.target.value)}
        />
        <div className="text-xs text-red-600">{validationError}</div>
      </div>

      <div className="flex flex-col space-y-1">
        <label htmlFor="details" className="font-medium">
          Details
        </label>
        <textarea
          id="details"
          className="h-40 w-full resize-none appearance-none rounded-md border border-gray-100 p-2 text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm"
          placeholder="Enter your details"
          onChange={(e) => onDetailsChanged(e.target.value)}
        ></textarea>
      </div>

      {isError && (
        <div className="text-red-600">Something went wrong. Please try again</div>
      )}
    </div>
  )
}

export default CreateTaskStep
