import { useState, useCallback } from 'react'

import { useToast } from '~/src/components/generic/Toast/hook/useToast'

import { Tag, RequestStatus } from '~/src/components/ReportIssue/types'

type Report = {
  subject: string
  details: string
  tag: Tag
}

type BugReportState = {
  isLoading: boolean
  status: RequestStatus
  reset: () => void
}

type UseBugReportReturnType = [
  submitBugReportHandler: ({ subject, details, tag }: Report) => void,
  state: BugReportState
]

const useBugReportQuery = (): UseBugReportReturnType => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<RequestStatus>(null)

  const toast = useToast()

  const reset = useCallback(() => {
    setStatus(null)
  }, [])

  const submitBugReportHandler = useCallback(
    async ({ subject, details, tag }: Report) => {
      setIsLoading(true)
      setStatus(null)

      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_REST_URL}/feedback/task`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: subject,
              notes: details,
              tag: tag,
            }),
          }
        )

        if (response.ok) {
          setStatus('success')
          toast.success('Feedback submitted. Thanks for your feedback!', {
            id: new Date().valueOf(),
          })
        } else {
          setStatus('error')
        }
      } catch (error) {
        setStatus('error')
      }

      setIsLoading(false)
    },
    [toast]
  )

  return [submitBugReportHandler, { isLoading, status, reset }]
}

export default useBugReportQuery
